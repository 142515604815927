import pt from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { getNewsletterSnippetContent } from "../../../selectors/content";

import { Box, Flex } from "@rebass/grid";

import configs from "../../../config";
import colors from "../../../theme/colors";

import Button from "../Button";
import IconClose from "../IconClose";

import { useSelector } from "react-redux";
import {
  LightBox,
  ModalBox,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalInput,
  ModalLabel,
} from "./components";

const { MAILCHIMP_SNIPPET } = configs;

const cookieName = "wfNewsletter";

const NewsletterSnippet = ({ setHasSeenNewsletter }) => {
  const [offset, setOffset] = useState(0);
  const [cookies, setCookie] = useCookies([cookieName]);
  const email = useRef();

  const content = useSelector(getNewsletterSnippetContent);

  const detectEscapeClick = (e) => {
    if (e.keyCode === 27) {
      setHasSeenNewsletter();
    }
  };

  useEffect(() => {
    // setOffset(window.pageYOffset + 60)
    setOffset(window.pageYOffset + window.innerHeight / 2);
    document.body.style.overflow = "hidden";
    window.addEventListener("keydown", detectEscapeClick);
    const newCookieValue =
      cookies[cookieName] !== undefined
        ? parseInt(cookies[cookieName], 10) + 1
        : 0;
    if (newCookieValue <= 2) {
      setCookie(cookieName, newCookieValue, { path: "/", maxAge: 7776000 }); // maxAge is 90 days
    }

    return () => {
      document.body.style.overflow = "unset";
      window.removeEventListener("keydown", detectEscapeClick);
    };
  }, []);
  console.log("content", content);
  return (
    <>
      <ModalBox
        // top={[offset]}
        data-aos="fade"
        data-aos-duration="125"
        top={0}
      >
        <LightBox />
        <ModalContainer top={[offset]}>
          <MailchimpSubscribe
            url={MAILCHIMP_SNIPPET}
            render={({ subscribe, status, message }) => {
              if (status === "success") {
                setTimeout(() => {
                  setCookie(cookieName, 2, { path: "/", maxAge: 7776000 }); // maxAge is 90 days
                  setHasSeenNewsletter();
                }, 1500);
              }
              return (
                <ModalContent
                  maxWidth={["300px", "400px", "600px", "700px"]}
                  flexDirection="column"
                  px={["20px", "30px", "60px", "92px"]}
                  py={["18px", "24px", "50px", "82px"]}
                >
                  <ModalHeader
                    width={["240px", "320px", "400px", "440px"]}
                    fontSize={["20px", "20px", "20px", "34px"]}
                  >
                    <h3>
                      <font>{content[0].value}</font>
                    </h3>
                  </ModalHeader>
                  <Flex
                    mt={["34px"]}
                    flexDirection={["column", "column", "column", "row"]}
                  >
                    <Box onClick={setHasSeenNewsletter}>
                      <IconClose color="black" />
                    </Box>
                    <Flex flexDirection="column" mr={["18px"]}>
                      <ModalLabel
                        fontSize={["8px", "8px", "10px", "12px"]}
                        mb={["4px", "4px", "4px", "8px"]}
                      >
                        <label htmlFor="email">EMAIL</label>
                      </ModalLabel>
                      <ModalInput
                        width={["100%", "100%", "100%", "380px"]}
                        fontSize={["12px", "12px", "12px", "18px"]}
                      >
                        <input name="email" type="email" ref={email} />
                      </ModalInput>
                    </Flex>
                    <Flex alignItems="flex-end" mt={["8px", "8px", "12px", 0]}>
                      <Button
                        text="Subscribe"
                        handleClick={() => {
                          subscribe({
                            NAME: "Newsletter subscription",
                            EMAIL: email.current.value,
                            PHONE: "123-123-1234",
                            WEBSITE: "Newsletter subscription",
                            MESSAGE: "Newsletter subscription",
                          });
                        }}
                      />
                    </Flex>
                  </Flex>
                  <Box>
                    {status === "sending" && (
                      <div style={{ color: "blue" }}>sending...</div>
                    )}
                    {status === "error" && (
                      <div
                        style={{ color: "red" }}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                    {status === "success" && (
                      <div
                        style={{ color: "green" }}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                  </Box>
                </ModalContent>
              );
            }}
          />
        </ModalContainer>
      </ModalBox>
    </>
  );
};

NewsletterSnippet.propTypes = {
  test: pt.bool.isRequired,
  setHasSeenNewsletter: pt.func.isRequired,
};

export default NewsletterSnippet;
