export const getHomepageContent = (state) => {
  const content = state.content.homepageHeroText
    ? {
        homepageVideo: state.content.homepageVideo,
        homepageHeroText: state.content.homepageHeroText,
        homepageHeroHighlightedText: state.content.homepageHeroHighlightedText,
        homepageHeroSubtitleText: state.content.homepageHeroSubtitleText,
        homepageHeroButtonText: state.content.homepageHeroButtonText,
        homepageVisionTextHeader: state.content.homepageVisionTextHeader,
        homepageVisionTextBody: state.content.homepageVisionTextBody,
        homepageVisionButton: state.content.homepageVisionButton,
        homepageVisionButtonLink: state.content.homepageVisionButtonLink,
        homepagePartnerTextHeader: state.content.homepagePartnerTextHeader,
        homepagePartnerTextBody: state.content.homepagePartnerTextBody,
        homepageValuePropsHeader: state.content.homepageValuePropsHeader,
        homepageValuePropsDescription:
          state.content.homepageValuePropsDescription,
        aboutPageVideoReelClientsImage:
          state.content.aboutPageVideoReelClientsImage.fields.file.url,
        caseStudiesHeroText: state.content.caseStudiesHeroText,
        footerSubscribeHeader: state.content.footerSubscribeHeader,
        footerSubscribeContent: state.content.footerSubscribeContent,
      }
    : {
        homepageHeroText: "",
        homepageHeroHighlightedText: "",
        homepageHeroSubtitleText: "",
        homepageVisionTextHeader: "",
        homepageVisionTextBody: [""],
        homepagePartnerTextHeader: "",
        homepagePartnerTextBody: "",
        homepageValuePropsHeader: ["", "", ""],
        homepageValuePropsDescription: ["", "", ""],
        aboutPageVideoReelClientsImage: "",
      };
  return content;
};

export const getFooterContent = (state) => {
  return {
    generalFooterTextLarge: state.content.generalFooterTextLarge,
    generalFooterTextLink: state.content.generalFooterTextLink,
  };
};

export const getAboutContent = (state) => {
  return {
    aboutPageHeroText: state.content.aboutPageHeroText,
    aboutPageHeroTextHighlight: state.content.aboutPageHeroTextHighlight,
  };
};

export const getContactContent = (state) => {
  return {
    contactPageTextHeader: state.content.contactPageTextHeader,
    contactPageTextDescriptions: state.content.contactPageTextDescriptions,
  };
};

export const getNewsletterSnippetContent = (state) => {
  const { newsletterSnippet } = state.content;
  const newsletterSnippetText = newsletterSnippet.content[0].content;

  return {
    ...newsletterSnippetText.map((textData) => {
      return {
        isOrange: textData.marks.length,
        value: textData.value,
      };
    }),
  };
};
