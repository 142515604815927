import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../common/Button";

import {
  // createBlockyNav,
  navigateToPortfolios,
} from "../../actions";

// import { StandOut } from "./StandOut";

import { getHomepageContent } from "../../selectors/content";

import bannerVideo from "./bannerVideo";
import ValueStatement from "./ValueStatement";

import SideNav from "../common/SideNav";
// import Footer from "../common/Footer";

import "../../resources/styles/main.scss";
import "./styles/index.scss";

import { FooterConsultation } from "../common/footer-consultation";
import { Footer2022 } from "../common/Footer2022";
import { FooterSubscribe } from "./FooterSubscribe";

// const cookieName = "wfNewsletter";

const HomepageStage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [cookies] = useCookies([cookieName]);
  // const [state, setState] = useState({
  //   showVideo: false,
  //   showNewsletter: false,
  //   firstInit: false,
  //   hasClosedNewsletter: false,
  //   // hasSeenNewsletter: false,
  //   // debouncer: null,
  // });
  // const cookieValue = parseInt(cookies[cookieName], 10) || 0;
  const heroContent = useSelector(getHomepageContent);
  // const heroText = heroContent !== undefined ? .homepageHeroText || ''
  // const heroTextHighlighted = heroContent.homepageHeroTextHighlighted || ''
  const {
    homepageVideo,
    homepageHeroText,
    homepageHeroHighlightedText,
    homepageHeroSubtitleText,
    homepageHeroButtonText,
    homepageVisionTextHeader,
    homepageVisionTextBody,
    homepageVisionButton,
    homepageVisionButtonLink,
    homepagePartnerTextHeader,
    homepagePartnerTextBody,
    footerSubscribeHeader,
    footerSubscribeContent,
  } = heroContent;

  // const scrollListener = () => {
  //   if (state.debouncer !== null) {
  //     clearTimeout(state.debouncer);
  //   }
  //   setState({
  //     debouncer: setTimeout(() => {
  //       if (window.pageYOffset > 600) {
  //         // 860 400
  //         setTimeout(() => {
  //           setState({
  //             ...state,
  //             hasSeenNewsletter: false,
  //             showNewsletter: true,
  //           });
  //           window.removeEventListener("scroll", scrollListener);
  //         }, 1000);
  //       }
  //     }, 10),
  //   });
  // };

  useEffect(() => {
    ReactGA.pageview("/");
    // if (cookieValue < 2) {
    //   window.addEventListener("scroll", scrollListener);
    // }
    // return () => {
    //   if (cookieValue < 2) {
    //     window.addEventListener("scroll", scrollListener);
    //   }
    // };
  }, []);

  if (homepageHeroText === "") {
    return <></>;
  }

  return (
    <>
      <div>
        <div
          data-aos="fade"
          data-aos-duration="200"
          style={{ position: "relative" }}
        >
          {bannerVideo(
            homepageVideo,
            homepageHeroText,
            homepageHeroHighlightedText,
            homepageHeroSubtitleText,
            homepageHeroButtonText
          )}
          <div className="homepage">
            {/* <ValueStatement
              homepageVisionTextHeader={homepageVisionTextHeader}
              homepageVisionTextBody={homepageVisionTextBody}
              homepageVisionButton={homepageVisionButton}
              homepageVisionButtonLink={homepageVisionButtonLink}
              history={history}
              navigateToPortfolios={() => {
                dispatch(navigateToPortfolios);
              }}
            /> */}
            {/* {state.showNewsletter &&
            !state.hasSeenNewsletter &&
            cookieValue < 2 ? (
              <NewsletterSnippet
                test={"yes"}
                setHasSeenNewsletter={() => {
                  setState({
                    hasSeenNewsletter: true,
                  });
                }}
              />
            ) : null} */}
            {/* <StandOut
              texts={{
                homepageValuePropsHeader,
                homepageValuePropsDescription,
              }}
            /> */}
            <section
              className="partner-statement-container"
              data-aos="fade"
              data-aos-duration="750"
              data-aos-delay="250"
            >
              <SideNav
                title="Our Partners"
                initialHeight={0}
                parentClassName="partner-statement-container"
                containerStyle={{ right: "0", left: "auto" }}
              />
              <div className="container-full-green partner-statement wrapper reveal">
                <div className="partner-statement-content text-container">
                  <h2 className="wf-text-white">{homepagePartnerTextHeader}</h2>
                  <p className="wf-text-custom">{homepagePartnerTextBody}</p>
                  <div className="links-container">
                    {/* <span style={{ marginRight: "40px" }}>
                      <Link className="wf-links" to="/portfolio">
                        Our Work
                      </Link>
                    </span>
                    <span>
                      <Link className="wf-links" to="/team">
                        Our Team
                      </Link>
                    </span> */}
                    <Button
                      text={homepageHeroButtonText}
                      isNewWindow
                      href="https://wakefield.agency/contact-us"
                    />
                  </div>
                  <div className="homepage-logo__container">
                    <div>
                      <a href="https://www.bcorporation.net/en-us/find-a-b-corp/company/wakefield-agency">
                        <div className="homepage-logo b-corp-logo" />
                      </a>
                    </div>
                    {/* Removed as requested in Jan 2023 */}
                    {/* <div>
                      <a href="https://marketingmadesimple.com/Nouver-Cheung">
                        <div className="homepage-logo storybrand-guide" />
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <Footer hasLine /> */}
          <FooterConsultation />
          <Footer2022 />
          <FooterSubscribe
            footerSubscribeContent={footerSubscribeContent}
            footerSubscribeHeader={footerSubscribeHeader}
          />
          <div />
        </div>
      </div>
    </>
  );
};

export default HomepageStage;
