import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { MAILCHIMP_SNIPPET } from "../../../.config";

import "./style.scss";

export const FooterSubscribe = ({
  footerSubscribeHeader,
  footerSubscribeContent,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  return (
    <div>
      <MailchimpSubscribe
        url={MAILCHIMP_SNIPPET}
        render={({ subscribe, status, message }) => (
          <form action="javascript:void(0);" method="get">
            <div className="footer-subscribe-container">
              <div>
                <h3>{footerSubscribeHeader}</h3>
                <p>{footerSubscribeContent}</p>
              </div>
              <div className="footer-subscribe-inputs">
                <input
                  name="name"
                  type="text"
                  placeholder="Name"
                  required
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <button
                  className="wf-contact-submit"
                  onClick={() => {
                    alert("Subscription coming soon...");
                    return;
                    subscribe({
                      EMAIL: email,
                      Name: name,
                      PHONE: "123-123-1234",
                      WEBSITE: "Homepage footer subscription",
                      MESSAGE: "Homepage footer subscription",
                    });
                  }}
                >
                  Subscription coming soon...
                </button>
              </div>
            </div>
            {status === "sending" && <div>sending...</div>}
            {status === "error" && <div>sending...</div>}
            {status === "success" && <div>{message}</div>}
          </form>
        )}
      />
    </div>
  );
};
